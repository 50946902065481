var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row align-items-center text-center page-headline mb-4"},[_c('div',{staticClass:"col-auto text-md-left"},[_c('h1',{staticClass:"display-3"},[_c('router-link',{staticClass:"mr-2",attrs:{"to":"/vendor/orders/available/"}},[_vm._v("Available orders")]),_c('span',{staticClass:"material-symbols-rounded fz-28"},[_vm._v(" chevron_right ")]),_vm._v(" Order #"+_vm._s(_vm.id)+" ("+_vm._s(_vm.order.createdAt ? _vm.$dateAndTime(_vm.order.createdAt) : "")+") ")],1)])]),(_vm.loading)?_c('div',{staticClass:"loader-box"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),(_vm.order)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('orderCard',{key:_vm.order.id,attrs:{"is-vendor":"","order":_vm.order}})],1)]):_vm._e(),(_vm.order && _vm.order.status === 0)?_c('div',{staticClass:"row my-0 mt-md-3"},[_c('div',{staticClass:"col-md-12 col-xl-4"},[_c('form',{attrs:{"action":"#","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.acceptOrder.apply(null, arguments)}}},[_c('h3',{staticClass:"header-title"},[_vm._v("Car & Driver")]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"required",attrs:{"for":"order-accept-car"}},[_vm._v("Car")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('multiselect',{staticClass:"w-100",class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.car.$error,
                    },attrs:{"options":_vm.carlist,"track-by":"id","label":"model","multiple":false,"allow-empty":false,"maxHeight":240,"tag-placeholder":"Select car","placeholder":""},model:{value:(_vm.form.car),callback:function ($$v) {_vm.$set(_vm.form, "car", $$v)},expression:"form.car"}}),(_vm.submitted && _vm.$v.form.car.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Car is required ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"required",attrs:{"for":"order-accept-reason"}},[_vm._v("Expected time for driver to arrive")]),_c('date-picker',{staticClass:"form-control form-control-timepicker",class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.reason.$error,
                  },attrs:{"format":"hh:mm a","value-type":"format","type":"time","placeholder":"hh:mm a"},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}}),(_vm.submitted && _vm.$v.form.reason.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Expected time is required field ")]):_vm._e()],1),_c('div',{staticClass:"form-group mb-0"},[_c('label',{staticClass:"required",attrs:{"for":"order-accept-driver"}},[_vm._v("Driver")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('multiselect',{staticClass:"w-100",class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.driver.$error,
                    },attrs:{"options":_vm.driverlist,"track-by":"id","custom-label":_vm.customLabel,"multiple":false,"allow-empty":false,"maxHeight":240,"tag-placeholder":"Select driver","placeholder":""},model:{value:(_vm.form.driver),callback:function ($$v) {_vm.$set(_vm.form, "driver", $$v)},expression:"form.driver"}}),(_vm.submitted && _vm.$v.form.driver.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Driver is required ")]):_vm._e()],1)])])]),_c('div',{staticClass:"text-center text-md-left my-3"},[_c('button',{staticClass:"btn btn-primary mw-230",attrs:{"type":"submit","disabled":_vm.wait}},[_vm._v(" Accept order ")])])])])]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }